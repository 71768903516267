import clsx from 'clsx'
import type { CSSProperties, ForwardedRef, MouseEvent } from 'react'
import { forwardRef } from 'react'
import type { TextColors, TextSizes } from '../text/types'

type StackAlignments =
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'space-between'
  | 'space-around'
  | 'baseline'
  | 'stretch'
  | 'normal'

export type SizeValues =
  | 0
  | 0.5
  | 1
  | 1.5
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20

type PaddingValues =
  | 0
  | 0.5
  | 1
  // | 1.5
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8

type WrapValues = 'wrap' | 'wrap-reverse' | 'nowrap'

type Padding1 = `${PaddingValues}`
type Padding2 = `${PaddingValues},${PaddingValues}`
type Padding3 = `${PaddingValues},${PaddingValues},${PaddingValues}`
type Padding4 =
  `${PaddingValues},${PaddingValues},${PaddingValues},${PaddingValues}`

type PaddingTuple = Padding1 | Padding2 | Padding3 | Padding4

type PaddingValue = SizeValues | PaddingTuple

export type StackProps = {
  children: React.ReactNode
  className?: string
  as?: React.ElementType
  color?: TextColors
  gap?: SizeValues
  align?: StackAlignments
  justify?: StackAlignments
  fontSize?: TextSizes
  row?: boolean
  reverse?: boolean
  grow?: number | string
  shrink?: number | string
  style?: CSSProperties
  wrap?: WrapValues
  /**
   * Takes a comma-separated string or a tuple of padding values
   *
   * - 1 value (1): all sides
   * - 2 values (1,2): block (top/bottom), inline (left/right)
   * - 3 values (1,2,1): top, inline (left/right), bottom
   * - 4 values (1,2,3,4): top, right, bottom, left
   *
   * Valid values are integers 0-8, 0.5, 1.5
   */
  padding?: PaddingValue
  id?: string

  onClick?: (event: MouseEvent<HTMLElement>) => void
}

const computeTuplePadding = (value: PaddingValue) => {
  if (typeof value === 'number') {
    return { padding: `var(--size${String(value).replace('.', '')})` }
  } else if (typeof value === 'string') {
    const positions = value.split(',')

    if (positions.length <= 4) {
      return { padding: positions.map((p) => `var(--size${p})`).join(' ') }
    } else {
      throw new Error('Invalid padding tuple')
    }
  } else {
    throw new Error('Invalid padding value')
  }
}

const computePadding = (padding?: PaddingValue): CSSProperties => {
  return padding ? computeTuplePadding(padding) : {}
}

export const Stack = forwardRef(
  (
    {
      children,
      className,
      color,
      as = 'div',
      gap = 0,
      align = 'normal',
      fontSize,
      justify = 'normal',
      row = false,
      reverse = false,
      grow = 0,
      shrink = 0,
      style,
      wrap = 'nowrap',
      padding,
      ...props
    }: StackProps,
    ref: ForwardedRef<HTMLElement>,
  ) => {
    const reversed = reverse ? '-reverse' : ''
    const styleVars = {
      ...computePadding(padding),
      ...style,
      '--stack-align-items': align,
      '--stack-direction': row ? `row${reversed}` : `column${reversed}`,
      '--stack-gap': gap,
      '--stack-grow': grow,
      '--stack-justify-content': justify,
      '--stack-shrink': shrink,
      '--stack-wrap': wrap,
      ...(color && { color: `var(--color-${color})` }),
      ...(fontSize && { fontSize: `var(--font-${fontSize})` }),
    } as CSSProperties

    const Component = as
    return (
      <Component
        className={clsx(['stack', className])}
        ref={ref}
        style={styleVars}
        {...props}
      >
        {children}
      </Component>
    )
  },
)

Stack.displayName = 'Stack'
